// extracted by mini-css-extract-plugin
export var button = "menu-module--button--2Oe6H";
export var alert = "menu-module--alert--yEyqJ";
export var eshopButton = "menu-module--eshopButton--1Vpcy";
export var menuText = "menu-module--menuText--11ZjE";
export var textOpen = "menu-module--textOpen--3Tobk";
export var menuContainer = "menu-module--menuContainer--1cyKu";
export var containerOpen = "menu-module--containerOpen--1W7Id";
export var menuCenter = "menu-module--menuCenter--1ReqR";
export var menuList = "menu-module--menuList--8qMr6";
export var services = "menu-module--services--1fHXt";
export var servicesOpen = "menu-module--servicesOpen--VG3lz";
export var follow = "menu-module--follow--3CAeb";
export var navIcon1 = "menu-module--navIcon1--2gdyb";
export var open = "menu-module--open--3uX2Y";
export var search = "menu-module--search--124zD";