import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
function SEO({ description, lang, meta, keywords, title, image, bodyAttr }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || (data.site && data.site.description) || "Klinika čínské a estetické medicíny Judity Halvové";
        const siteTitle = (data.site && data.site.title) || "Beauty Body Clinic";
        const siteAuthor = (data.site && data.site.author && data.site.author.name) || "";
        const metaImage =
          image && image.asset ? imageUrlFor(buildImageObj(image)).width(1200).url() : "https://cdn.sanity.io/images/sfqldr6j/production/2463b81b8d1a4398909031f93c193dc8eb94595a-1100x733.jpg?w=2000&h=2000&fit=max";

        const pageTitle = title || siteTitle;

        return (
          <Helmet
            bodyAttributes={bodyAttr}
            htmlAttributes={{ lang }}
            title={pageTitle}
            titleTemplate={pageTitle === siteTitle ? siteTitle : `%s | ${siteTitle}`}
            meta={[
              {
                name: "google-site-verification",
                content: "7MfJFsxBVui5UlEBExUFeMW9-Q6g9fPgoaxwzvbqaV0",
              },
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:title",
                content: title,
              },
              {
                property: "og:description",
                content: metaDescription,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                property: "og:image",
                content: metaImage,
              },
              {
                name: "twitter:card",
                content: "summary",
              },
              {
                name: "twitter:creator",
                content: siteAuthor,
              },
              {
                name: "twitter:title",
                content: title,
              },
              {
                name: "twitter:description",
                content: metaDescription,
              },
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: "keywords",
                      content: keywords.join(", "),
                    }
                  : []
              )
              .concat(meta)}
          >
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link
              href="https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@300;500&family=Raleway:wght@300&display=swap"
              rel="stylesheet"
            />
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: "cs",
  meta: [],
  keywords: [
    "Beauty Body Clinic",
    "Beauty",
    "Akupunktura",
    "Implantace Vlasů",
    "Dermapen",
    "Judita Halvová",
  ],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
      }
    }
  }
`;
