import React from "react";
import { cn } from "../lib/helpers";

import { Link } from "gatsby";

import * as styles from "./footer.module.css";
import * as commonStyles from "../styles/common.module.css";

import Arrow from "../images/sipka.png";

const Footer = ({ services = [], magazin = [] }) => (
  <footer>
    <div className={styles.container}>
      <div>
        <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
          Beauty Body Clinic
        </h2>
        <ul>
          <li className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
            <Link to={"/akce/"}>Akce</Link>
          </li>
          <li className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
            <Link to={"/o-nas/"}>O nás</Link>
          </li>
          <li className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
            <Link to={"/cenik/"}>Ceník</Link>
          </li>
          <li className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
            <Link to={"/kontakt/"}>Kontakt</Link>
          </li>
          <li className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
            <Link to={"/konzultace/"}>Konzultace</Link>
          </li>
          <li className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
            <Link to={"/obchodni-podminky/"}>Obchodní podmínky</Link>
          </li>
          <li className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}>
            <Link to={"/zasady-ochrany-osobnich-udaju/"}>Zásady ochrany osobních údajů</Link>
          </li>
        </ul>
      </div>
      <div>
        <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
          Naše služby
        </h2>
        <ul>
          <ul>
            {services.map((service, index) => {
              if (service.disabled) {
                return;
              }

              return (
                <li
                  className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}
                  key={`serviceLink_${index}`}
                >
                  <Link to={`/${service?.slug?.current}/`}>{service?.title}</Link>
                </li>
              );
            })}
          </ul>
        </ul>
      </div>
      <div>
        <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
          Magazín
        </h2>
        <ul>
          <ul>
            {magazin.map((article, index) => (
              <li
                className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}
                key={`articleLink_${index}`}
              >
                <Link to={`/magazin/${article?.slug?.current}/`}>{article?.title}</Link>
              </li>
            ))}
            <li
              className={cn(styles.link, commonStyles.colorDarkGray, commonStyles.fontReleway)}
              key={`magazinLink`}
            >
              <Link to={`/magazin/`}>
                <div className={cn(styles.more, commonStyles.colorAccent)}>
                  <img src={Arrow} alt="Šipka" /> VÍCE ČLÁNKŮ
                </div>
              </Link>
            </li>
          </ul>
        </ul>
      </div>
    </div>
    <div>
      <div className={cn(styles.ending1, commonStyles.colorAccent, commonStyles.fontReleway)}>
        Beauty Body Clinic, 2022
      </div>
      <div className={cn(styles.ending2, commonStyles.fontButler)}>
        Made with love by crunchy.studio
      </div>
    </div>
  </footer>
);

export default Footer;
