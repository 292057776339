import React from "react";
import TagManager from "react-gtm-module";
import { cn } from "../lib/helpers";

import Menu from "./menu/menu";
import Header from "./header";
import CookieConsent from "./cookieConsent";
import Footer from "./footer";

import * as styles from "./layout.module.css";
import * as commonStyles from "../styles/common.module.css";

const tagManagerArgs = {
  gtmId: "AW-11336266631",
};

if (typeof window !== "undefined") TagManager.initialize(tagManagerArgs);

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    document.body.style.overflow = "inherit";
  }

  render() {
    const { children, logoAnimation, services, magazin, tags } = this.props;
    return (
      <div className={cn(styles.container, commonStyles.fadeIn)}>
        <Menu services={services} tags={tags} />
        <Header logoAnimation={logoAnimation} />
        <div>{children}</div>
        <CookieConsent />
        <Footer services={services} magazin={magazin} />
      </div>
    );
  }
}

export default Layout;
