// extracted by mini-css-extract-plugin
export var colorAccent = "common-module--colorAccent--3IXqE";
export var colorDarkGray = "common-module--colorDarkGray--GdwEW";
export var fontButler = "common-module--fontButler--2qg3k";
export var fontButlerMedium = "common-module--fontButlerMedium--oar6F";
export var fontReleway = "common-module--fontReleway--OREau";
export var animationDuration2 = "common-module--animationDuration2--36CRw";
export var animationDelay1 = "common-module--animationDelay1--14PNR";
export var animationDelay12 = "common-module--animationDelay12--ghCAy";
export var animationDelay2 = "common-module--animationDelay2--2oD8I";
export var animationDelay3 = "common-module--animationDelay3--3XcS-";
export var letterSpacing1 = "common-module--letterSpacing1--gmNSo";
export var fadeIn = "common-module--fadeIn--3bOhZ";
export var tags = "common-module--tags--2rqaY";
export var fadeInRight = "common-module--fadeInRight--1XaHE";
export var fadeInLeft = "common-module--fadeInLeft--2_3VR";
export var gradient = "common-module--gradient--oaWaL";
export var parallax1 = "common-module--parallax1--hwFO0";
export var parallax2 = "common-module--parallax2--1ZGZf";
export var parallax3 = "common-module--parallax3--1A6JE";