import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./cookieConsent.module.css";
import * as commonStyles from "../styles/common.module.css";

const isBrowser = typeof window !== "undefined";

function getCookie(name) {
  if (!isBrowser) return;
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
    var end = document.cookie.indexOf(";", begin);
    if (end == -1) {
      end = dc.length;
    }
  }
  // because unescape has been deprecated, replaced with decodeURI
  //return unescape(dc.substring(begin + prefix.length, end));
  return decodeURI(dc.substring(begin + prefix.length, end));
}

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: getCookie("gatsby-gdpr-google-analytics") === null,
    };
  }

  render() {
    if (!isBrowser || !this.state.show) return null;
    return (
      <div className={styles.container}>
        <div className={cn(commonStyles.fontButler, commonStyles.colorDarkGray)}>
          Ke zlepšení uživatelsého zážitku používáme cookies. Zvolte prosím zda nám to dovolíte:
        </div>
        <button
          className={cn(commonStyles.fontReleway, commonStyles.colorAccent)}
          onClick={() => {
            document.cookie = "gatsby-gdpr-google-analytics=true; SameSite=Strict;";
            this.setState(() => {
              return {show: false};
            });
          }}
        >
          Ano
        </button>
        <button
          className={cn(commonStyles.fontReleway, commonStyles.colorAccent)}
          onClick={() => {
            document.cookie = "gatsby-gdpr-google-analytics=false; SameSite=Strict;";
            this.setState(() => {
              return {show: false};
            });
          }}
        >
          Ne
        </button>
      </div>
    )
  }
}

export default CookieConsent;
